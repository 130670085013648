// Variable overrides first
$dark: #000;
$enable-shadows: true;
$orange: #f87d09;

// Then import Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import './modules/globals';
@import './modules/main';
@import './modules/404';

.bg-vid {
  width: 100%;
  max-height: 512px;
}

.outer-div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.button-bar {
  margin-bottom: 1rem;
}

.grid-wrapper {
  flex: 1 1 auto;
}

.bug-reports {  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.fade-in-out {
  opacity: 0;
}

.fade-in-out.show {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

#fileBugContainer {
  transition: all 0.5s ease;
}
/**
 * Definition of variables
 */
 :root {
    --white:        #FFFFFF;
    --black:        #000000;
    --dark:         #282c34;
    --primary:      #0076ff;
    --indigo:       #6200EE;
    --danger:       #FF0000;
    --warning:      #f87d09;
    --problem:      #E87074;
    --gray:         #8A8A8E;
    --grayed:       #e0e0e0;
    --disabled:     #e5e5e5;
    --sky-blue:     #1da1f2;
    --silver-sand:  #C4C4C6;
    --success:      #44db5e;
}
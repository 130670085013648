.not-found {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  
  h1 {
    font-family: Pacifico, sans-serif;
    font-size: 4em;
    color: $primary;
    margin: 0;
  }

  h2 {
    font-weight: 300;
    font-family: sans-serif;
    margin-top: 1rem;
  }
}